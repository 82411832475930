'use strict';

import focusOut from './accessibility/focusOut';
import formCheckboxesKeyboardTrigger from './accessibility/formCheckboxesKeyboardTrigger';

var _ = require('lodash');

const forceSliderInit = () => {
    $('.menu-carousel').slick("refresh");
    //$('.menu-carousel').slick('slickNext');
}

/**
 * Define desktop menu item behavior on mouse enter
 * @param {Event} e - The Event interface
 */

const desktopMenuItemOnMouseEnter = (e) => {
    // Dropdown items
    if ($(e.currentTarget).hasClass('dropdown')) {
        let dropdownMenu = $(e.currentTarget).children('[data-toggle="dropdown"]');

        dropdownMenu.closest('.nav-item.dropdown').addClass('show');
        dropdownMenu.siblings('.dropdown-menu').addClass('show').attr('aria-hidden', 'false');
        dropdownMenu.attr('aria-expanded', 'true');
    } else {
        // 'Static' menu items - without subcategories. The class show enable proper menu item underline at the correct timeout
        $(e.currentTarget).not('.header-menu-site-search').addClass('show');
    }
    forceSliderInit();
};

/**
 * Define desktop menu item behavior on mouse leave
 * @param {Event} e - The Event interface
 */

const desktopMenuItemOnMouseLeave = (e) => {
    // The following control ensure the overlay does not disappear when hovering on its input autocomplete box(es)
    if (e.relatedTarget) {
        $(e.currentTarget).removeClass('show');
        $(e.currentTarget).children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
        $(e.currentTarget).children('.nav-link').attr('aria-expanded', 'false');
    }
};

/**
 * Collection of mobile menu behaviors triggered at specific moments of the mobile menu life-cycle
 */

const mobileMenu = (function () {
    // Triggered on mobile menu closing (tap on the hamburger icon)
    const onClose = () => {
        $('.main-menu').removeClass('open'); // Close mobile menu if open
        $('body, html').removeClass('overflow-hidden position-fixed'); // position: fixed is a CSS hack for older iOS versions to make the document unscrollable when navigating the mobile menu
    };

    // Triggered on tapping on a first-level menu item
    const onSlideIn = () => {
        $('nav.navbar .menu-group').addClass('slide');
        $('.menu-group-overflow-overlay').removeClass('overflow-y-scroll').addClass('overflow-hidden');
        $('.header-menu-mobile-top-bar').removeClass('d-flex').addClass('d-none');
    };

    // Triggered on tapping the 'Go Back' button in the second-level menu panel
    const onSlideOut = () => {
        $('nav.navbar .menu-group').removeClass('slide');
        $('.menu-group-overflow-overlay').addClass('overflow-y-scroll').removeClass('overflow-hidden');
        $('.header-menu-mobile-top-bar').addClass('d-flex').removeClass('d-none');
    };

    // Triggered on breakpoint changing (more specifically, triggered at the threshold of the 'lg' breakpoint)
    const toDesktopMenu = () => {
        $('nav.navbar .menu-group').removeClass('slide'); // Remove the 'slide' class from second-level menu if present. The 'slide' activates the slide effect on second level menu on mobile devices
        $('.header-menu-mobile-top-bar').addClass('d-flex').removeClass('d-none');
        $('.main-menu').css('top', 0); // Remove mobile offset from top for the menu
    };

    return {
        onClose: onClose,
        onSlideIn: onSlideIn,
        onSlideOut: onSlideOut,
        toDesktopMenu: toDesktopMenu
    };
})();

/**
 * Header menu scroll effect. The effect is currently active on DESKTOP devices only. On tablet and mobile devices, menu sticks to the top of the viewport
 */
// This effect was removed
/* const scrollEffect = () => {
    if ($(window).scrollTop() > 0) {
        $('#maincontent').addClass('shift-top');
        $('.header-body').slideUp(100);
        $('.header-menu-sticky-logo').slideDown(100);
    } else {
        $('#maincontent').removeClass('shift-top');
        $('.header-banner, .header-body').slideDown(100);
        $('.header-menu-sticky-logo').slideUp(100);
    }
}; */

class Menu {
    constructor () {
        this.overlayTrigger = '.header-head-overlay-wrapper-trigger';
    }

    desktopHeaderOverlays () {
        // Popups behavior for 'Store Locator', 'My Account' and 'Minicart' overlays on desktop devices. On mobile and tablet devices, overlays are not active.
        $(this.overlayTrigger).on('mouseover', function () {
            $(this).find('.header-head-overlay-wrapper, .overlay-mark').addClass('show');
        }).on('mouseout', function (e) {
            // The following control ensure the overlay does not disappear when hovering on its input autocomplete box(es)
            if (!$(e.target).is('input')) {
                $(this).find('.header-head-overlay-wrapper, .overlay-mark').removeClass('show');
            }
        });

        // Prevent user-agent default form submission behavior on Enter keydown
        formCheckboxesKeyboardTrigger(this.overlayTrigger + '.account #header-rememberMe');

        // Accessibility. Open header overlays on focus
        $('body').on('focus', '.header-head-overlay-wrapper-trigger > .nav-link', (e) => {
            e.preventDefault();
            $(e.target).closest('.header-head-overlay-wrapper-trigger').find('.header-head-overlay-wrapper, .overlay-mark').addClass('show');
        });

        // Accessibility. Close header overlays on focus out
        focusOut('.header-head-overlay-wrapper-trigger', (currentTarget) => {
            currentTarget.find('.header-head-overlay-wrapper').removeClass('show');
            currentTarget.find('.overlay-mark').removeClass('show');
        });
    }

    desktopMenuScrollEffect () {
        let lgUp = Breakpoints.get('lgUp');
        let debouncedScrollEffect = _.debounce(scrollEffect, 40, {leading: true});

        lgUp.on('enter', () => {
            $(window).on('scroll', debouncedScrollEffect);
        });

        // Remove the scroll effect on smaller-than-lg viewports
        lgUp.on('leave', () => {
            $(window).off('scroll', debouncedScrollEffect);
        });
    }

    dropdownClosingBehavior () {
        // By default, Bootstrap dropdowns close when clicking outside of them. This code prevents this default behavior from executing.
        $('.header-menu-first-level, .header-menu-site-search').on('hide.bs.dropdown', function (e) {
            if (e.clickEvent) {
                e.preventDefault();
            }
        });
    }

    menu () {
        let lgBreakpoint = Breakpoints.get('lgUp'); // Includes viewports larger than the Boostrap lg breakpoint

        // 1. Menu DESKTOP behavior. Second level menus open on hover, while third level menus open on collapse
        lgBreakpoint.on('enter', () => {
            mobileMenu.onClose();

            mobileMenu.toDesktopMenu();

            $('.header-menu [data-toggle="dropdown"]')
                .on('click', function (e) {
                    e.preventDefault();
                });

            // Menu item hover effects are delayed thanks to the jQuery hoverIntent plugin - https://briancherne.github.io/jquery-hoverIntent/
            $('.navbar-nav .nav-item').hoverIntent({
                over: desktopMenuItemOnMouseEnter,
                out: desktopMenuItemOnMouseLeave,
                timeout: 200
            });
        });

        // Close desktop button click
        $('.close-desktop-menu-btn').on('click', () =>{
            var $target = $('.dropdown-menu.header-menu-second-level');
            $target.removeClass('show');
            $target.children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
            $target.children('.nav-link').attr('aria-expanded', 'false');
        });

        // 2. Menu TABLET and MOBILE devices
        // Disable menu items hover behavior on mobile and tablet devices. This prevents dropdowns from appearing when hovering over menu items on mobile and tablet devices.
        lgBreakpoint.on('leave', () => {
            $('.navbar-nav .nav-item.dropdown')
                .off('mouseenter mouseleave');
        });

        $('.nav-item .nav-link.clickable').on('click', (e) => {
            let url = $(e.currentTarget).attr('href');
            window.location = url;
        });

        if (Breakpoints.is('lgDown'))
        {
            $('.nav-item .nav-link:not(.clickable)').on('click', (e) => {
                e.preventDefault();
            });
        }

    }

    mobilemenuAccessibility () {
        focusOut('.navbar', () => {
            mobileMenu.onClose();
        });

        focusOut('.header-menu-second-level', () => {
            mobileMenu.onSlideOut();
        });
    }

    mobileMenuCloseButton () {
        $('body').on('click', '.navbar .close-button', function (e) {
            e.preventDefault();

            mobileMenu.onClose();

            $('.navbar-toggler').focus();

            $('.main-menu').attr('aria-hidden', 'true');
            $('.main-menu').siblings().attr('aria-hidden', 'false');
            $('header').siblings().attr('aria-hidden', 'false');
            $('header').removeClass('position-fixed');
        });
    }

    mobileMenuGoBackButton () {
        $('body').on('click', '.go-back', () => {
            mobileMenu.onSlideOut();

            $('.dropdown-menu.show').closest('.nav-item').removeClass('show');
            $('.dropdown-menu.show').removeClass('show');
        });
    }

    mobileMenuSlide () {
        // Called when tapping on a first-level menu item
        $('.header-menu-first-level .dropdown').on('show.bs.dropdown', function () {
            mobileMenu.onSlideIn();
        });
    }

    mobileMenuToggler () {
        // Open/close mobile menu hamburger button
        $('body').on('click', '.navbar-toggler', (e) => {
            e.preventDefault();
            let h = $('header').outerHeight();
            $('.main-menu').toggleClass('open');
            $('.main-menu').css('top', h);// position the top of the menu under the header
            $('body, html').toggleClass('overflow-hidden position-fixed w-100'); // position: fixed is a CSS hack for older iOS versions to make the document unscrollable when navigating the mobile menu

            $('.main-menu').attr('aria-hidden', 'false');
            $('.main-menu').siblings().attr('aria-hidden', 'true');
            $('header').siblings().attr('aria-hidden', 'true');
            $('header').toggleClass('position-fixed');

            // toggle menu hamburger button icon
            $('#hamburger-btn').toggleClass('d-none');
            $('#hamburger-close-btn').toggleClass('d-none');
        });


    }

    searchCloseButton () {
        // Search close button on desktop devices
        $('body').on('click', '.header-search-close-button', () => {
            $('.header-search-desktop-dropdown-toggle').dropdown('toggle');
        });

        // Search close button on mobile/tablet devices
        $('body').on('click','.header-search-mobile-close-button', () => {
            $('.header-search-mobile-dropdown-toggle').dropdown('toggle');
        });

    }

    get init () {
        return {
            desktopHeaderOverlays: this.desktopHeaderOverlays(),
            //desktopMenuScrollEffect: this.desktopMenuScrollEffect(),
            dropdownClosingBehavior: this.dropdownClosingBehavior(),
            menu: this.menu(),
            mobilemenuAccessibility: this.mobilemenuAccessibility(),
            mobileMenuCloseButton: this.mobileMenuCloseButton(),
            mobileMenuGoBackButton: this.mobileMenuGoBackButton(),
            mobileMenuSlide: this.mobileMenuSlide(),
            mobileMenuToggler: this.mobileMenuToggler(),
            searchCloseButton: this.searchCloseButton()
        };
    }
}

const menu = new Menu;
export default menu;
